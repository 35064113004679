// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: true,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Rsi y Sma',
      icon: 'mdi-menu',
      to: '/rsi-and-sma',
    },
    {
      title: 'Rsi y Sma Filter',
      icon: 'mdi-cloud',
      to: '/rsi-filter',
    },
    {
      title: 'Scheduled Operation',
      icon: 'mdi-clock',
      to: '/scheduled-operation',
    },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
